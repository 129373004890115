import React from "react"
import { graphql } from "gatsby"
import {
  ContentfulComposePageType,
  ContentfulGeneralPageType,
} from "../types/contentful.types"
import BackgroundContainer from "../components/background-container"
import DownloadPopup from "../components/download-popup"
import { useState } from "react"
import Sections from "../components/sections/sections"
import Menu from "../components/menu/menu"
import Hero from "../components/hero"
import MenuMobile from "../components/menu/menu-mobile"
import { isMobile } from "react-device-detect"
import TemplateWrapper from "../components/TemplateWrapper"

export const query = graphql`
  query GeneralPageTemplateQuery($slug: String!) {
    contentfulGeneralPage(
      compose__page: { elemMatch: { slug: { eq: $slug } } }
    ) {
      hero {
        image {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          title
          file {
            contentType
            url
          }
        }
        imageMobile {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          title
          file {
            contentType
            url
          }
        }
        videoPlaceholder {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          title
          file {
            contentType
            url
          }
        }
        imageNearTheText {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 800)
            }
            publicURL
          }
        }
        imageNearTheTextPosition
        text {
          raw
        }
      }
      sections {
        columns {
          __typename
          ... on ContentfulComponentYoutubeVideo {
            id
            youtubeUrl {
              youtubeUrl
            }
          }
          ... on ContentfulComponentSlotsWithFilter {
            id
            internalName
            slots {
              slotIcon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 330)
                  }
                }
              }
              gameIframeUrl
              slotInfoLink
              compose__page {
                title
                slug
              }
              categories {
                id
                title
              }
            }
          }
          ... on ContentfulComponentSlotsTypes {
            id
            __typename
            slots {
              id
              compose__page {
                slug
                title
              }
              categories {
                title
                id
              }
              gameIframeUrl
              slotInfoLink
              slotIcon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 40)
                  }
                }
                title
              }
              description {
                raw
              }
            }
          }
          ... on ContentfulComponentGallery {
            id
            __typename
            galleryType
            title
            media {
              title
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1920, quality: 20)
                }
              }
            }
          }
          ... on ContentfulComponentTextImage {
            id
            __typename
            text {
              raw
              references {
                ... on ContentfulComponentImageLink {
                  __typename
                  contentful_id
                  title
                  linkToUrl
                  openInNewWindow
                  image {
                    localFile {
                      publicURL
                    }
                  }
                  linkToInnerPage {
                    slug
                  }
                }
                ... on ContentfulComponentStores {
                  contentful_id
                  __typename
                  storesList {
                    name
                    mobile {
                      mobile
                    }
                    desktop {
                      desktop
                    }
                    }
                }
              }
            }
            textBg
            imagesSide
            gallery {
              id
              media {
                id
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1920, quality: 60)
                  }
                }
                title
                file {
                  contentType
                  url
                }
              }
            }
          }
          ... on ContentfulComponentSlotsTypes {
            id
            __typename
            showDescription
          }
          ... on ContentfulComponentCards {
            id
            title
            additionalText {
              raw
            }
            bottomText {
              raw
            }
            iconPlace
            showTitles
            backgroundColor
            itemsInRow
            cards {
              id
              title
              iconSizeOnMobile
              readMore {
                compose__page {
                  slug
                }
              }
              icon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 80)
                  }
                }
                title
              }
              textAlignment
              textColor
              text {
                raw
              }
            }
          }
          ... on ContentfulComponentTextImageGroup {
            id
            __typename
            title
            spaceBetween
            description {
              raw
            }
            bottomText {
              raw
            }
            textImages {
              text {
                raw
                references {
                  ... on ContentfulComponentImageLink {
                    id
                    __typename
                    contentful_id
                    title
                    linkToUrl
                    openInNewWindow
                    image {
                      localFile {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                    linkToInnerPage {
                      slug
                    }
                  }
                  ... on ContentfulComponentStores {
                    contentful_id
                    __typename
                    storesList {
                      name
                      mobile {
                        mobile
                      }
                      desktop {
                        desktop
                      }
                      }
                  }
                }
              }
              textBg
              imagesSide
              gallery {
                id
                media {
                  id
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 1920, quality: 85)
                    }
                  }
                  title
                  file {
                    contentType
                    url
                  }
                }
              }
            }
          }
          ... on ContentfulComponentSimpleText {
            id
            backgroundColor
            text {
              raw
              references {
                ... on ContentfulComponentImageLink {
                  id
                  __typename
                  contentful_id
                  title
                  linkToUrl
                  openInNewWindow
                  image {
                    localFile {
                      publicURL
                    }
                  }
                  linkToInnerPage {
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }

    contentfulComposePage(slug: { eq: $slug }) {
      impressionLink {
        impressionLink
      }
      seo {
        description
        keywords
        no_index
        no_follow
        title
        canonical
        schemaJson {
          schemaJson
        }
        socialImage {
          url
        }
      }
    }
  }
`

const GeneralPageTemplate = ({ location, data }) => {
  const { hero, sections }: ContentfulGeneralPageType =
    data.contentfulGeneralPage
  const { seo, impressionLink }: ContentfulComposePageType = data.contentfulComposePage
  const [showDownloadPopup, setShowDownloadPopup] = useState<boolean>(false)
  return (
    <TemplateWrapper seo={seo} impressionLink={impressionLink?.impressionLink}>
      <DownloadPopup
        onCloseClick={() => setShowDownloadPopup(false)}
        show={showDownloadPopup}
      />

      <div className="app-container">
        <MenuMobile onDownloadClick={() => setShowDownloadPopup(true)} />
        <Menu onDownloadClick={() => setShowDownloadPopup(true)} />

        {hero && (
          <BackgroundContainer
            videoPlaceholder={hero.videoPlaceholder}
            image={
              isMobile && hero.imageMobile?.file?.url
                ? hero.imageMobile
                : hero.image
            }
            fader={hero.imageNearTheText ? false : true}
          >
            <Hero
              data={hero}
              onDownloadClick={() => setShowDownloadPopup(true)}
            />
          </BackgroundContainer>
        )}

        <main>
          <Sections
            seo={seo}
            sections={sections}
            onDownloadClick={() => setShowDownloadPopup(true)}
          />
        </main>
      </div>
    </TemplateWrapper>
  );
}

export default GeneralPageTemplate
